import * as React from 'react';
import styled from 'styled-components';

import SEO from '../components/seo';
import { graphql } from 'gatsby';

import { useTranslation } from 'gatsby-plugin-react-i18next';

const Container = styled.div`
	display: flex;
	flex-flow: column;
	align-items: center;
	background-color: var(--grey);
	min-height: calc(100vh - var(--nav-bar-height) - var(--footer-height));
	padding: var(--content-top-margin) var(--padding) var(--content-bottom-margin) var(--padding);
`;

const Content = styled.div`
	max-width: var(--content-width);
`;

const NotFoundPage = () => {
	const { t } = useTranslation();
	return (
		<Container>
			<SEO title="404: Not found" />
			<Content>
				<h1>{t('Sellist lehte ei leitud!')}</h1>
				<p>{t('Palun kontrollige, et sisestasite URLi õigesti või kasutage menüüd, et leida soovitud leht.')}</p>
			</Content>
		</Container>
	);
};

export default NotFoundPage;

export const pageQuery = graphql`
	query NotFoundPageQuery($language: String!) {
		locales: allLocale(filter: { language: { eq: $language } }) {
			edges {
				node {
					ns
					data
					language
				}
			}
		}
	}
`;
