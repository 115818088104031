/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import * as React from 'react';
import Helmet from 'react-helmet';
import { graphql, useStaticQuery } from 'gatsby';

interface SEO {
	description?: string;
	lang?: string;
	meta?: [];
	title?: string;
	image?: string | null | undefined;
}

export const SEO: React.FC<SEO> = ({ description, lang, meta, title, image }) => {
	const { site, metaImg } = useStaticQuery(
	  graphql`
          query AllPageMeta {
              site {
                  siteMetadata {
                      siteUrl
                      title
                      description
                      author
                      image
                  }
              }
              metaImg: file(name: { eq: "tallinn-2035" }, extension: { eq: "png" }) {
                  publicURL
              }
          }
	  `,
	);

	const metaTitle = `${title} | Arengustrateegia Tallinn 2035` || 'Arengustrateegia Tallinn 2035';
	const metaDescription = description || site.siteMetadata.description;
	const metaImage = `${site.siteMetadata.siteUrl}${image || metaImg.publicURL}`;

	const metaData = [
		{
			name: `description`,
			content: metaDescription,
		},
		{
			property: `og:title`,
			content: metaTitle,
		},
		{
			property: `og:description`,
			content: metaDescription,
		},
		{
			property: `og:type`,
			content: `website`,
		},
		{
			property: `og:image`,
			content: metaImage,
		},
		{
			name: `twitter:card`,
			content: `summary`,
		},
		{
			name: `twitter:creator`,
			content: site.siteMetadata.author,
		},
		{
			name: `twitter:title`,
			content: metaTitle,
		},
		{
			name: `twitter:description`,
			content: metaDescription,
		},
		{
			name: `twitter:image`,
			content: metaImage,
		},
		...(meta || []),
	];

	const linkData = [
		{
			href: 'https://api.tiles.mapbox.com/mapbox-gl-js/v1.8.1/mapbox-gl.css',
			rel: 'stylesheet',
		},
	];

	return (
		<Helmet
			htmlAttributes={{
				lang,
			}}
			title={title}
			titleTemplate={`%s | ${site.siteMetadata.title}`}
			meta={metaData}
			link={linkData}
		/>
	);
};

SEO.defaultProps = {
	lang: `en`,
	meta: [],
	description: ``,
};

export default SEO;
